import * as React from 'react';
import { Box, Flex, Text, Grid, Icon, Image } from '@churni/styleguide';
import { Feature } from '@churni/common';

export function Analyze(props: { img: string }): React.ReactElement {
  const { img } = props;

  return (
    <Box>
      <Flex
        flexWrap={['wrap', 'wrap', 'nowrap']}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box width={[12, 12, 6]} pr={[0, 0, 8]}>
          <Box>
            <Text variant="display4" as={'h2'}>{`Insights & Analytics`}</Text>
          </Box>
          <Box mt={2}>
            <Text
              variant={'subtitle3'}
            >{`Identify the reasons why customers are leaving and take the right actions to prevent future customer churn.`}</Text>
          </Box>
          <Grid columns={2} mt={5} columnGap={7}>
            <Feature
              icon={<Icon.Filter size={'medium'} />}
              title={'Insights'}
              description="Filter & organize your data to turn customer feedback into valuable insights. "
            />
            <Feature
              icon={<Icon.AlertTriangle size={'medium'} />}
              title={'Identify at-risk customers'}
              description={
                'Track customers who have shown an intention to cancel. '
              }
            />
          </Grid>
        </Box>
        <Box width={[12, 12, 6]} pt={[2, 2, 0]}>
          <Image
            sx={{
              border: 1,
              borderColor: 'border',
              borderRadius: 'radius',
              boxShadow: 'white2'
            }}
            src={img}
          />
        </Box>
      </Flex>
    </Box>
  );
}
