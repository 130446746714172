import * as React from 'react';
import { Box, Flex, Text } from '@churni/styleguide';
import { CTAButton } from '../CTAButton';
import { HeroVideo } from './HeroVideo';

function CTAButtons(): React.ReactElement {
  return (
    <Flex flexWrap={'wrap'}>
      <CTAButton
        my={1}
        py={2}
        sx={{ fontSize: 3 }}
        ml={0}
        className={'homepage-hero-cta'}
        title="Try Churni for Free"
      />
    </Flex>
  );
}

export function HeroContent(props: {
  h1?: string;
  subtitle?: string;
  personalizePoster: string;
  retainPoster: string;
  analyzePoster: string;
  personalizeSrc: string;
  retainSrc: string;
  analyzeSrc: string;
}): React.ReactElement {
  const {
    h1,
    subtitle,
    personalizePoster,
    retainPoster,
    analyzePoster,
    personalizeSrc,
    retainSrc,
    analyzeSrc
  } = props;

  return (
    <Flex flexWrap={'wrap'} alignItems={'center'}>
      <Box sx={{ width: ['100%', '100%', '100%', '40%'] }}>
        <Text variant={'display2'} as={'h1'}>
          {h1}
        </Text>

        <Box mt={3}>
          <Text variant={'heading3'} color={'text'}>
            {subtitle}
          </Text>
        </Box>

        <Box mt={3}>
          <CTAButtons />
        </Box>

        <Box mt={-1}>
          <Text variant="body3">
            14-day free trial, no credit card required
          </Text>
        </Box>
      </Box>
      <Box
        sx={{ width: ['100%', '100%', '100%', '60%'] }}
        pl={[0, 0, 0, 5]}
        mt={[5, 5, 5, 0]}
      >
        <HeroVideo
          personalizePoster={personalizePoster}
          retainPoster={retainPoster}
          analyzePoster={analyzePoster}
          personalizeSrc={personalizeSrc}
          retainSrc={retainSrc}
          analyzeSrc={analyzeSrc}
        />
      </Box>
    </Flex>
  );
}
