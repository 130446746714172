import * as React from 'react';
import { Box, Text } from '@churni/styleguide';

export function Feature(props: {
  title: string;
  description: string;
  icon: React.ReactElement;
}): React.ReactElement {
  const { title, description, icon } = props;
  return (
    <Box>
      <Text
        variant={'body1'}
        sx={{ verticalAlign: 'middle', color: 'primary' }}
      >
        {icon} <Text sx={{ verticalAlign: 'middle' }}>{title}</Text>
      </Text>
      <Box>
        <Text variant="body3">{description}</Text>
      </Box>
    </Box>
  );
}
