import * as React from 'react';
import { Box, Flex, Text, Grid, Icon } from '@churni/styleguide';
import Img from 'gatsby-image';

import { Feature } from '@churni/common';

export function Integrations(props: {
  integrationsLogos: {
    zapier: string;
    crisp: string;
    calendly: string;
    intercom: string;
    drift: string;
  };
}): React.ReactElement {
  const { integrationsLogos } = props;

  return (
    <Box>
      <Flex
        alignItems={'center'}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
      >
        <Box width={[12, 12, 6]} sx={{ display: ['none', 'none', 'block'] }}>
          <Box sx={{ maxWidth: 300, margin: '0 auto' }}>
            <IntegrationsLogos logos={integrationsLogos} />
          </Box>
        </Box>
        <Box width={[12, 12, 6]} pl={[0, 2]} mt={[5, 0]}>
          <Box>
            <Box>
              <Text variant="display4" as={'h2'}>{`Apps & Integrations`}</Text>
            </Box>
            <Box mt={2}>
              <Text variant={'subtitle3'}>
                {`Churni.io integrates with the applications you already use to automate actions or engage customers.`}
              </Text>
            </Box>
          </Box>
          <Grid columns={2} mt={5} columnGap={6}>
            <Feature
              icon={<Icon.Box size={'medium'} />}
              title={'Webhooks & Zapier'}
              description="Receive notifications, send emails, apply discounts, enrich your CRM or Analytics platform with customer feedback, etc."
            />

            <Feature
              icon={<Icon.Code size={'medium'} />}
              title={'Widgets & Embeds'}
              description={
                'Embed iframe apps in your survey (Calendar, Feature tracker) or use widgets such as Intercom or Drift.'
              }
            />
          </Grid>
        </Box>
        <Box width={[12, 12, 6]} sx={{ display: ['block', 'block', 'none'] }}>
          <Box sx={{ maxWidth: 300, margin: '0 auto' }}>
            <IntegrationsLogos logos={integrationsLogos} />
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}

export function IntegrationsLogos(props: {
  logos: {
    zapier: string;
    crisp: string;
    calendly: string;
    intercom: string;
    drift: string;
  };
}): React.ReactElement {
  const { logos } = props;
  return (
    <Box sx={{ position: 'relative', height: 300 }}>
      <Box
        sx={{
          position: 'absolute',
          top: 30,
          left: 20,
          width: 64,
          height: 64
        }}
      >
        <Img fixed={logos.calendly} fadeIn={false} alt={'Calendly'} />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: 24,
          right: 0,
          width: 64,
          height: 64
        }}
      >
        <Img fixed={logos.drift} fadeIn={false} alt={'Drift'} />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 60,

          width: 64,
          height: 64
        }}
      >
        {' '}
        <Img fixed={logos.crisp} fadeIn={false} alt={'Crisp'} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 150,
          left: 120,
          width: 64,
          height: 64
        }}
      >
        <Img fixed={logos.zapier} fadeIn={false} alt={'Zapier'} />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 32,
          right: 30,
          width: 64,
          height: 64
        }}
      >
        <Img fixed={logos.intercom} fadeIn={false} alt={'Intercom'} />
      </Box>
    </Box>
  );
}
