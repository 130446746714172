import * as React from 'react';
import { Box, Input, Flex, Text, useTheme } from '@churni/styleguide';

export function InputGroup(props: {
  inputLabel: string | React.ReactElement;
  value: any;
  onChangeValue: (val: any) => void;
}): React.ReactElement {
  const { value, onChangeValue, inputLabel } = props;
  const theme = useTheme();

  return (
    <Flex
      alignItems={'center'}
      justifyContent={'space-between'}
      sx={{
        px: 1,
        border: 1,
        borderColor: 'border',
        borderRadius: 'radius'
      }}
    >
      <Input
        type="number"
        variant={'passive'}
        flex={1}
        sx={{
          ...theme.text.body2,
          px: 0
        }}
        value={value}
        onChangeValue={onChangeValue}
      />
      <Box mr={[0, 2]}>
        <Text variant={'body3'}>{inputLabel}</Text>
      </Box>
    </Flex>
  );
}
