import * as React from 'react';
import { Loading } from '..';

export function Suspense(props: {
  children: React.ReactElement | React.ReactElement[];
  fallback?: React.ReactElement;
}): React.ReactElement {
  const { children, fallback = <Loading />, ...rest } = props;

  const isSSR = typeof window === 'undefined';

  if (isSSR) {
    return fallback;
  }

  return (
    <React.Suspense {...rest} fallback={fallback}>
      {children}
    </React.Suspense>
  );
}
