import * as React from 'react';
import { Button, Icon } from '@churni/styleguide';
import { routes } from '@churni/common';

export function CTAButton(props: {
  title?: string;
  email?: string;
  className: string;
  displayChevron?: boolean;
}): React.ReactElement {
  const {
    title = 'Get started',
    email = '',
    className,
    displayChevron = true,
    ...rest
  } = props;

  return (
    <Button
      as="a"
      href={routes.toAppURL(routes.signup({ email }))}
      py={'12px'}
      px={3}
      ml={[0, 1]}
      type="submit"
      variant="secondary"
      className={className}
      {...rest}
    >
      {title} {displayChevron && <Icon.ArrowRight />}
    </Button>
  );
}
