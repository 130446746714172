import * as React from 'react';
import {
  Box,
  useIntersection,
  Flex,
  Text,
  Grid,
  Icon
} from '@churni/styleguide';

const roundProgress = (currentTime: number, duration: number) => {
  return Math.round((currentTime / duration) * 100);
};

export function HeroVideo(props: {
  personalizePoster: string;
  retainPoster: string;
  analyzePoster: string;
  personalizeSrc: string;
  retainSrc: string;
  analyzeSrc: string;
}): React.ReactElement {
  const {
    personalizePoster,
    retainPoster,
    analyzePoster,
    personalizeSrc,
    retainSrc,
    analyzeSrc
  } = props;

  const target = React.useRef(null);
  const intersecting = useIntersection(target, { threshold: 0.7 });
  const [runningVideo, setRunningVideo] = React.useState(null);
  const [currentVideoRef, setCurrentVideoRef] = React.useState(null);

  const [personalizeProgress, setPersonalizeProgress] = React.useState(null);
  const [retainProgress, setRetainProgress] = React.useState(null);
  const [analyzeProgress, setAnalyzeProgress] = React.useState(null);

  const personalizeRef = React.useRef();
  const retainRef = React.useRef();
  const analyzeRef = React.useRef();

  const playPersonalizeVideo = () => {
    setRunningVideo('personalize');
    setCurrentVideoRef(personalizeRef);

    try {
      personalizeRef.current.currentTime = 0;
      personalizeRef.current.play();
      retainRef.current.pause();
      analyzeRef.current.pause();
    } catch (err) {}
  };

  const playRetainVideo = () => {
    setRunningVideo('retain');
    setCurrentVideoRef(retainRef);

    try {
      retainRef.current.currentTime = 0;
      personalizeRef.current.pause();
      retainRef.current.play();
      analyzeRef.current.pause();
    } catch (err) {}
  };

  const playAnalyzeVideo = () => {
    setRunningVideo('analyze');
    setCurrentVideoRef(analyzeRef);

    try {
      analyzeRef.current.currentTime = 0;
      personalizeRef.current.pause();
      retainRef.current.pause();
      analyzeRef.current.play();
    } catch (err) {}
  };

  React.useEffect(() => {
    personalizeRef.current.addEventListener('ended', playRetainVideo);
    retainRef.current.addEventListener('ended', playAnalyzeVideo);
    analyzeRef.current.addEventListener('ended', playPersonalizeVideo);

    setInterval(function() {
      if (personalizeRef.current) {
        setPersonalizeProgress(
          roundProgress(
            personalizeRef.current.currentTime,
            personalizeRef.current.duration
          )
        );
      }

      if (personalizeRef.current) {
        setRetainProgress(
          roundProgress(
            retainRef.current.currentTime,
            retainRef.current.duration
          )
        );
      }

      if (analyzeRef.current) {
        setAnalyzeProgress(
          roundProgress(
            analyzeRef.current.currentTime,
            analyzeRef.current.duration
          )
        );
      }
    });
  }, []);

  React.useEffect(() => {
    if (!runningVideo && intersecting) {
      playPersonalizeVideo();
      return;
    }

    if (!intersecting && currentVideoRef && currentVideoRef.current) {
      currentVideoRef.current.pause();
      return;
    }

    if (intersecting && currentVideoRef && currentVideoRef.current) {
      currentVideoRef.current.play();
      return;
    }
  }, [currentVideoRef, intersecting, runningVideo]);

  return (
    <Box>
      <Box ref={target} mx={[-3, 0]}>
        <Video
          src={personalizeSrc}
          isRunning={runningVideo === 'personalize' && personalizeProgress > 0}
          ref={personalizeRef}
          poster={personalizePoster}
          sx={{
            display:
              runningVideo === 'personalize' || !runningVideo ? 'block' : 'none'
          }}
        />
        <Video
          src={retainSrc}
          isRunning={runningVideo === 'retain' && retainProgress > 0}
          ref={retainRef}
          poster={retainPoster}
          sx={{ display: runningVideo === 'retain' ? 'block' : 'none' }}
        />
        <Video
          src={analyzeSrc}
          isRunning={runningVideo === 'analyze' && analyzeProgress > 0}
          ref={analyzeRef}
          poster={analyzePoster}
          sx={{ display: runningVideo === 'analyze' ? 'block' : 'none' }}
        />
      </Box>

      <Grid columns={3} columnGap={0}>
        <VideoSectionBlock
          progress={personalizeProgress}
          isRunning={runningVideo === 'personalize'}
          onClick={playPersonalizeVideo}
          desktopText={'1. Personalize your cancel flow'}
          mobileText={'1. Personalize'}
        />

        <VideoSectionBlock
          progress={retainProgress}
          onClick={playRetainVideo}
          isRunning={runningVideo === 'retain'}
          desktopText={'2. Retain customers'}
          mobileText={'2. Retain'}
        />

        <VideoSectionBlock
          progress={analyzeProgress}
          onClick={playAnalyzeVideo}
          isRunning={runningVideo === 'analyze'}
          desktopText={'3. Analyze churn'}
          mobileText={'3. Analyze'}
        />
      </Grid>
    </Box>
  );
}

export function VideoSectionBlock(props: {
  children: string;
  progress: number;
  onClick: () => void;
  isRunning: () => void;
  illustration: any;
  desktopText: string;
  mobileText: string;
}): React.ReactElement {
  const { progress, onClick, desktopText, mobileText, isRunning } = props;

  return (
    <Box>
      <Flex
        onClick={onClick}
        flexDirection={'column'}
        sx={{
          position: 'relative',
          cursor: 'pointer',
          height: '100%'
        }}
        justifyContent="center"
        py={[1, 1]}
      >
        <Flex alignItems={'center'} flexDirection={['column', 'row']}>
          <Box
            sx={{ display: ['block', 'block', 'block', 'none'], mx: 'auto' }}
          >
            <Text variant="subtitle4" color={'heading'}>
              {mobileText}
            </Text>
          </Box>

          <Box sx={{ display: ['none', 'none', 'none', 'block'], mx: 'auto' }}>
            <Text variant="subtitle4" color={isRunning ? 'heading' : 'text'}>
              {desktopText}
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Box
        sx={{
          position: 'relative',
          width: '80%',
          height: 2,
          margin: 'auto',
          bg: 'border'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: '0px',
            borderRadius: 'radius',
            height: 2,
            bg: 'secondary',
            width: `${progress}%`,
            transition: 'width .1s linear'
          }}
        ></Box>
      </Box>
    </Box>
  );
}
export const Video = React.forwardRef(function VideoWithRef(
  props: { isRunning: boolean; src: string; poster: string; sx: any },
  ref: React.Ref<any>
): React.ReactElement {
  const { isRunning, src, poster, sx } = props;
  return (
    <Box
      sx={{
        position: 'relative',
        cursor: !isRunning ? 'pointer' : 'initial',
        ...sx
      }}
      onClick={
        !isRunning
          ? () => {
              ref.current && ref.current.play();
            }
          : null
      }
    >
      <Box
        minHeight={['initial', 'initial', 'initial', 363]}
        elevation={2}
        width={'100%'}
        as={'video'}
        muted
        playsInline
        poster={poster}
        sx={{
          objectFit: 'contain',
          borderRadius: [0, 'rounded']
        }}
        ref={ref}
      >
        <source type="video/mp4" src={src} />
        Sorry, your browser doesnt support video
      </Box>
      {!isRunning && (
        <Box
          variant={'text'}
          sx={{
            position: 'absolute',
            border: 2,
            borderRadius: 'circle',
            borderColor: 'primary',
            width: 60,
            height: 60,
            top: '50%',
            bg: 'primary',
            right: '50%',
            transform: 'translate(50%,-50%)'
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                top: '12px',
                right: '10px',
                position: 'absolute',
                color: 'white'
              }}
            >
              <Icon.Play sx={{ width: 32, height: 32, fill: 'white' }} />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
});
