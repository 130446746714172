/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import { graphql } from 'gatsby';
import personalizeMP4 from '../assets/homepage/flow.mp4';
import retainMP4 from '../assets/homepage/demo.mp4';
import analyzeMP4 from '../assets/homepage/dashboard.mp4';
import { Box, Text, Container } from '@churni/styleguide';
import { SEO } from '../components';

import {
  MarketingLayout,
  Personalize,
  Analyze,
  Retain,
  Integrations,
  ReduceChurn,
  CTAButton,
  HeroContent
} from '@churni/common';

export interface LandingPageProps {
  h1?: string;
  subtitle?: string;
  firstSectionTitle?: string;
  CTABottom?: string;
  heroVideo: {
    personalizePoster: string;
    retainPoster: string;
    analyzePoster: string;
    personalizeVideo: string;
    retainVideo: string;
    analyzeVideo: string;
  };
  integrationsLogos: {
    zapier: string;
    crisp: string;
    calendly: string;
    intercom: string;
    drift: string;
  };

  personalizeImg: string;
  retainImg: string;
  analyzeImg: string;
  integrationsImg: React.ReactElement;
}

export default function Homepage(props: any) {
  const pageTitle = 'Churni | Customer Retention & Churn Management Software';
  const pageDescription =
    'Reduce customer churn with a better cancellation flow. Analyze churn feedback and implement retention strategies to save customers at scale.';
  const h1 = 'Reduce churn with a better cancellation flow';
  const subtitle =
    'Understand why your customers are leaving and save up to 20% of customers before they cancel.';
  const firstSectionTitle = 'What if you decreased churn by 20%?';
  const CTABottom = 'Ready to reduce churn?';

  const personalizeImg = props.data.personalizeImg.childImageSharp.fixed.src;
  const retainImg = props.data.retainImg.childImageSharp.fixed.src;
  const analyzeImg = props.data.analyzeImg.childImageSharp.fixed.src;
  const heroVideo = {
    personalizePoster:
      props.data.heroVideoPersonalizePoster.childImageSharp.fluid.src,
    retainPoster: props.data.heroVideoRetainPoster.childImageSharp.fluid.src,
    analyzePoster: props.data.heroVideoAnalyzePoster.childImageSharp.fluid.src,
    personalizeVideo: personalizeMP4,
    retainVideo: retainMP4,
    analyzeVideo: analyzeMP4
  };
  const integrationsLogos = {
    zapier: props.data.zapier.childImageSharp.fixed,
    calendly: props.data.calendly.childImageSharp.fixed,
    drift: props.data.drift.childImageSharp.fixed,
    crisp: props.data.crisp.childImageSharp.fixed,
    intercom: props.data.intercom.childImageSharp.fixed
  };

  return (
    <>
      <SEO
        title={pageTitle}
        titleTemplate={'%s'}
        description={pageDescription}
      />
      <Box sx={{ width: '100%' }}>
        <MarketingLayout>
          <Container pt={[9, 10]} pb={[5, 7, 13]}>
            <HeroContent
              h1={h1}
              subtitle={subtitle}
              personalizePoster={heroVideo.personalizePoster}
              retainPoster={heroVideo.retainPoster}
              analyzePoster={heroVideo.analyzePoster}
              personalizeSrc={heroVideo.personalizeVideo}
              retainSrc={heroVideo.retainVideo}
              analyzeSrc={heroVideo.analyzeVideo}
            />
          </Container>

          <Box mt={[7, 9]} pt={[0]}>
            <ReduceChurn title={firstSectionTitle} showGrowthLink={false} />
          </Box>
          <Box py={[9, 11]} pb={9}>
            <Container>
              <Box>
                <Box mb={[9, 12]} id={'personalize'}>
                  <Personalize img={personalizeImg} />
                </Box>
                <Box mb={[9, 12]} id={'retain'}>
                  <Retain img={retainImg} />
                </Box>
                <Box mb={[9, 12]} id={'analyze'}>
                  <Analyze img={analyzeImg} />
                </Box>
                <Box mb={[0, 0]} id={'integrations'}>
                  <Integrations integrationsLogos={integrationsLogos} />
                </Box>
              </Box>
            </Container>
          </Box>
          <Box
            sx={{
              background: t => t.colors.gradientBG
            }}
            py={8}
          >
            <Container>
              <Box sx={{ textAlign: 'center', position: 'relative' }}>
                <Text variant={'display3'} as={'h2'} color={'textOnPrimary'}>
                  {CTABottom}
                </Text>
                <Box mt={3}>
                  <Text
                    variant={'heading3'}
                    sx={{ color: 'rgba(255,255,255,0.6)' }}
                  >{`Get set up in just a few minutes.`}</Text>
                </Box>
                <Box mt={3}>
                  <CTAButton
                    title={`Try it free`}
                    className={'homepage-setup-cta'}
                  />
                </Box>
              </Box>
            </Container>
          </Box>
        </MarketingLayout>
      </Box>
    </>
  );
}

export const query = graphql`
  query Homepage {
    personalizeImg: file(relativePath: { eq: "homepage/flow-4-3.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 800, height: 600, quality: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    retainImg: file(relativePath: { eq: "homepage/demo-4-3.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 800, height: 600, quality: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    analyzeImg: file(relativePath: { eq: "homepage/dashboard-4-3.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 800, height: 600, quality: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    heroVideoPersonalizePoster: file(
      relativePath: { eq: "homepage/flow.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1200, quality: 30) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroVideoRetainPoster: file(relativePath: { eq: "homepage/demo.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1200, quality: 30) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroVideoAnalyzePoster: file(
      relativePath: { eq: "homepage/dashboard.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1200, quality: 30) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zapier: file(relativePath: { eq: "homepage/logo/zapier.png" }) {
      childImageSharp {
        fixed(width: 64, height: 64) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    calendly: file(relativePath: { eq: "homepage/logo/calendly.png" }) {
      childImageSharp {
        fixed(width: 64, height: 64) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    drift: file(relativePath: { eq: "homepage/logo/drift.png" }) {
      childImageSharp {
        fixed(width: 64, height: 64) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    crisp: file(relativePath: { eq: "homepage/logo/crisp.png" }) {
      childImageSharp {
        fixed(width: 64, height: 64) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    intercom: file(relativePath: { eq: "homepage/logo/intercom.png" }) {
      childImageSharp {
        fixed(width: 64, height: 64) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;
