import { Input as NativeInput, InputProps } from '@rebass/forms';
import * as React from 'react';
import { usePrimitives } from '../..';

export const Input = React.forwardRef(function InputWithRef(
  props: InputProps & {
    onChangeValue?: (newValue: string) => void;
    flex?: boolean;
    heightFlex?: boolean;
    focus?: boolean;
    size?: 'normal' | 'small';
  },
  ref: React.Ref<any>
): React.ReactElement {
  const {
    onChangeValue,
    variant,
    flex = false,
    heightFlex = false,
    focus = false,
    sx,
    size = 'normal',
    ...rest
  } = props;

  const { input } = usePrimitives();
  const inputRef = ref || React.createRef();

  React.useEffect(() => {
    if (focus) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e: React.SyntheticEvent) => {
    if (onChangeValue) {
      onChangeValue(e.currentTarget.value);
    }

    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <NativeInput
      ref={inputRef}
      onChange={onChange}
      sx={{
        ...input.styles,
        ...input.variants[variant || input.defaultVariant],
        ...(flex ? { flex: 1 } : { width: '100%' }),
        ...(heightFlex ? { height: '100%' } : { height: 'input' }),
        ...(size === 'small' ? input.smallStyles : {}),
        ...sx
      }}
      {...rest}
    />
  );
});
