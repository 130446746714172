import * as React from 'react';
import { Box, Flex, Text, Grid, Icon, Image } from '@churni/styleguide';

import { Feature } from '@churni/common';

export function Retain(props: { img: string }): React.ReactElement {
  const { img } = props;

  return (
    <Box>
      <Flex
        flexWrap={['wrap', 'wrap', 'nowrap']}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box width={6} sx={{ display: ['none', 'none', 'block'] }}>
          <Image
            sx={{
              border: 1,
              borderColor: 'border',
              borderRadius: 'radius',
              boxShadow: 'white2'
            }}
            src={img}
          />
        </Box>
        <Box width={[12, 12, 6]} pl={[0, 0, 8]}>
          <Box mt={[0, 0]}>
            <Text
              variant="display4"
              as={'h2'}
            >{`Retain customers with a high performing cancel experience`}</Text>
          </Box>
          <Box mt={2}>
            <Text variant={'subtitle3'}>
              Many customers will churn for reasons that could easily be
              prevented. Save customers by presenting them with a solution
              adapted specifically to their problem.
            </Text>
          </Box>
          <Grid columns={2} mt={5} columnGap={6}>
            <Feature
              icon={<Icon.Message size={'medium'} />}
              title={'Engage customers'}
              description="Embed apps such as chatbots or booking calendars to create a communication with your customers."
            />

            <Feature
              icon={<Icon.Gift size={'medium'} />}
              title={'Retention offers'}
              description={
                'Make offers such as giving a free month, a discount or scheduling a call to save customers.'
              }
            />
          </Grid>
        </Box>
        <Box width={12} pt={2} sx={{ display: ['block', 'block', 'none'] }}>
          <Image
            sx={{
              border: 1,
              borderColor: 'border',
              borderRadius: 'radius',
              boxShadow: 'white2'
            }}
            src={img}
          />
        </Box>
      </Flex>
    </Box>
  );
}
