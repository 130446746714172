import * as React from 'react';
import { Box, Flex, Text, Grid, Icon, Image } from '@churni/styleguide';

import { Feature } from '@churni/common';

export function Personalize(props: { img: string }): React.ReactElement {
  const { img } = props;

  return (
    <Box>
      <Flex
        flexWrap={['wrap', 'wrap', 'nowrap']}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box pr={[0, 0, 8]} width={[12, 12, 6]}>
          <Box>
            <Text
              variant="display4"
              as={'h2'}
            >{`Personalize your cancellation experience`}</Text>
          </Box>
          <Box mt={2}>
            <Text variant="subtitle3">
              {`Build a branded & personalized customer cancellation flow to ask questions relevant to your product. Easily make changes without engineering resources.`}
            </Text>
          </Box>
          <Grid columns={2} mt={5} columnGap={[3, 6]}>
            <Feature
              icon={<Icon.Edit size={'medium'} />}
              title={'Easy to build'}
              description={
                'Use templates to get started. Add new blocks, questions and reorganize your content with drag & drop.'
              }
            />
            <Feature
              icon={<Icon.Share size={'medium'} />}
              title={'Variables & Jumps'}
              description="Create personalized question paths with logic jumps & variables to collect more specific feedback."
            />
          </Grid>
        </Box>
        <Box width={[12, 12, 6]} pt={[2, 2, 0]}>
          <Image
            sx={{
              border: 1,
              borderColor: 'border',
              borderRadius: 'radius',
              boxShadow: 'white2'
            }}
            src={img}
          />
        </Box>
      </Flex>
    </Box>
  );
}
