import * as React from 'react';
import { Box, Text, Container } from '@churni/styleguide';
import { ChurnCalculator } from './ChurnCalculator';

export function ReduceChurn(props: {
  title?: string;
  showGrowthLink: boolean;
}): React.ReactElement {
  const {
    title = 'What if you decreased your churn by 20%?',
    showGrowthLink = true
  } = props;

  return (
    <Box
      pb={9}
      sx={{
        position: 'relative'
      }}
    >
      <Box
        sx={{
          position: `absolute`,
          width: `100%`,
          height: ['100%', '100%', 'calc(100% + 220px)'],
          bottom: 0,

          clipPath: [
            'none',
            'none',
            `polygon(0 15%, 100% 0%, 100% 100%, 0% 100%)`
          ],
          background: t => t.colors.gradientBG,
          borderBottom: 1,
          borderColor: 'border'
        }}
      />
      <Box sx={{ position: 'relative' }}>
        <Container maxWidth={950} pt={5}>
          <Text variant={'display3'} color={'white'} as={'h2'}>
            {title}
          </Text>
          <Box
            sx={{ bg: 'marketingBG', borderRadius: 'rounded' }}
            mt={3}
            px={[2, 5]}
            py={5}
          >
            <ChurnCalculator showGrowthLink={showGrowthLink} />
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
