import * as React from 'react';
import { Image as NativeImage, ImageProps } from 'rebass';

export const Image = React.forwardRef(function ImageWithRef(
  props: ImageProps & { lazyload: boolean },
  ref
) {
  const { lazyload } = props;

  const placeholderSrc = (width, height) =>
    `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;

  return (
    <NativeImage
      ref={ref}
      {...props}
      src={lazyload ? placeholderSrc(500, 500) : props.src}
      className={lazyload ? 'lazyload' : ''}
    />
  );
});
