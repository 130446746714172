import * as React from 'react';
import {
  Box,
  Grid,
  Text,
  Flex,
  Link,
  useColors,
  Button,
  Suspense
} from '@churni/styleguide';
import { InputGroup, useScenario } from '../Calculator';
import querystring from 'querystring';
const LineChart = React.lazy(() => import('../Calculator/LineChart'));

export function ChurnCalculator(props: { showGrowthLink: boolean }) {
  const [mode, setMode] = React.useState('mrr');
  const colors = useColors();
  const [detailsDisplayed, setDetailsDisplayed] = React.useState(false);
  const scenario1 = useScenario({
    churnRate: 8,
    initialMRR: 100000,
    newRevenue: 10000,
    growthRate: 2,
    growthMode: 'flat',
    months: 36
  });

  const scenario2 = useScenario({
    churnRate: scenario1.churnRate * 0.8,
    initialMRR: scenario1.initialMRR,
    newRevenue: scenario1.newRevenue,
    growthRate: scenario1.growthRate,
    growthMode: scenario1.growthMode,
    months: 36
  });

  React.useEffect(() => {
    scenario2.setInitialMRR(scenario1.initialMRR);
  }, [scenario1.initialMRR, scenario2]);
  React.useEffect(() => {
    scenario2.setChurnRate(scenario1.churnRate * 0.8);
  }, [scenario1.churnRate, scenario1.initialMRR, scenario2]);
  React.useEffect(() => {
    scenario2.setNewRevenue(scenario1.newRevenue);
  }, [scenario1.newRevenue, scenario2]);

  const differenceMRR =
    scenario1.projection.length && scenario2.projection.length
      ? scenario2.projection[35].endingMRR - scenario1.projection[35].endingMRR
      : 0;

  const differenceTotal =
    scenario1.projection.length && scenario2.projection.length
      ? scenario2.projection[35].total - scenario1.projection[35].total
      : 0;

  const sharableQS = querystring.stringify({
    s1_churnrate: scenario1.churnRate,
    s1_initialmrr: scenario1.initialMRR,
    s1_newrev: scenario1.newRevenue,
    s1_growthrate: scenario1.setGrowthRate,
    s1_growthmode: scenario1.growthMode,
    s2_churnrate: scenario2.churnRate,
    s2_initialmrr: scenario2.initialMRR,
    s2_newrev: scenario2.newRevenue,
    s2_growthrate: scenario2.setGrowthRate,
    s2_growthmode: scenario2.growthMode,
    months: 36
  });

  const dataset = {
    labels: Object.keys(scenario1.projection),
    datasets: [
      {
        label: `Scenario ${scenario1.churnRate.toFixed(2)}% churn`,
        fill: false,
        lineTension: 0.1,
        backgroundColor: colors.primary,
        borderColor: colors.primary,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: colors.primary,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: colors.primary,
        pointHoverBorderColor: colors.primary,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: scenario1.projection.map(val =>
          mode === 'mrr' ? val.endingMRR : val.total
        ),
        fill: false
      },
      {
        label: `Scenario ${scenario2.churnRate.toFixed(2)}% churn`,
        fill: false,
        lineTension: 0.1,
        backgroundColor: colors.secondary,
        borderColor: colors.secondary,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: colors.secondary,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: colors.secondary,
        pointHoverBorderColor: colors.secondary,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: scenario2.projection.map(val =>
          mode === 'mrr' ? val.endingMRR : val.total
        )
      }
    ]
  };

  return (
    <Box>
      <Flex alignItems="center" justifyContent="center" flexWrap={'wrap'}>
        <Box width={[12, 12, 4]}>
          <Grid columns={[3, 3, 1]} columnGap={[1, 3]} rowGap={1}>
            <Box>
              <Text variant="overline2" sx={{ fontWeight: 2 }}>
                Current MRR
              </Text>
              <InputGroup
                inputLabel={'$'}
                type="number"
                value={scenario1.initialMRR}
                onChangeValue={val => scenario1.setInitialMRR(Number(val))}
              />
            </Box>
            <Box>
              <Text variant="overline2" sx={{ fontWeight: 2 }}>
                Churn Rate
              </Text>
              <InputGroup
                inputLabel={'% / mo'}
                type={'number'}
                value={scenario1.churnRate}
                onChangeValue={val => scenario1.setChurnRate(Number(val))}
              />
            </Box>
            <Box>
              <Text variant="overline2" sx={{ fontWeight: 2 }}>
                New Revenue
              </Text>
              <InputGroup
                inputLabel={'$ / mo'}
                type={'number'}
                value={scenario1.newRevenue}
                onChangeValue={val => scenario1.setNewRevenue(Number(val))}
              />
            </Box>
          </Grid>
        </Box>
        <Box
          sx={{ textAlign: 'center' }}
          width={[12, 12, 8]}
          mt={[5, 5, 2]}
          pl={[0, 0, 8]}
        >
          <Box>
            <Text variant={'subtitle2'}>
              Over 3 years, by reducing your churn from{' '}
              {scenario1.churnRate.toFixed(2)} to{' '}
              {scenario2.churnRate.toFixed(2)} (20% reduction), you could earn
              more every month :
            </Text>
          </Box>
          <Box mt={3}>
            <Text variant={'display3'} color={'primary'}>
              ${differenceMRR.toLocaleString('en')}
            </Text>
          </Box>
          <Box mt={3}>
            <Text variant={'subtitle3'} color={'text'}>
              Or about ${differenceTotal.toLocaleString('en')} in total over 3
              years
            </Text>
          </Box>

          {!detailsDisplayed ? (
            <Button
              mt={3}
              variant="primaryOutline"
              onClick={() => setDetailsDisplayed(true)}
            >
              Not Convinced?
            </Button>
          ) : (
            <Box
              sx={{
                width: '60%',
                height: 2,
                bg: 'border',
                mx: 'auto',
                mt: 3,
                display: ['block', 'block', 'none']
              }}
            />
          )}

          {props.showGrowthLink && (
            <Box>
              <Text variant="body3" color={'secondaryText'}>
                Not convinced? Check our{' '}
                <Link
                  sx={{ textDecoration: 'underline' }}
                  to={`/growth-calculator?${sharableQS}`}
                >
                  growth calculator
                </Link>
              </Text>
            </Box>
          )}
        </Box>
      </Flex>
      {detailsDisplayed && (
        <>
          <Box
            sx={{
              width: '60%',
              height: 2,
              bg: 'border',
              mx: 'auto',
              mt: 7,
              display: ['none', 'none', 'block']
            }}
          />
          <Flex
            alignItems="center"
            width={12}
            sx={{ mx: 'auto' }}
            mt={[3, 3, 7]}
            flexWrap={'wrap'}
          >
            <Flex flexDirection={'column'} width={[12, 12, 4]} pl={7}>
              <Flex alignItems={'center'}>
                <Box
                  mr={1}
                  sx={{
                    width: 20,
                    height: 20,
                    bg: 'secondary',
                    borderRadius: 'radius'
                  }}
                ></Box>
                <Text variant="body2">
                  Scenario {scenario2.churnRate.toFixed(2)}% churn rate
                </Text>
              </Flex>
              <Flex alignItems={'center'} mt={[1, 1, 1]}>
                <Box
                  mr={1}
                  sx={{
                    width: 20,
                    height: 20,
                    bg: 'primary',
                    borderRadius: 'radius'
                  }}
                ></Box>
                <Text variant="body2">
                  Scenario {scenario1.churnRate.toFixed(2)}% churn rate
                </Text>
              </Flex>
            </Flex>
            <Box width={[12, 12, 8]} pl={[0, 0, 8]} mt={[3, 3, 0]}>
              <Suspense>
                <LineChart dataset={dataset} />
              </Suspense>
            </Box>
          </Flex>
        </>
      )}
    </Box>
  );
}
