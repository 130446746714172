import * as React from 'react';

type RateGrowthProps = { growthMode: 'rate'; growthRate: number };
type FlatGrowthProps = { growthMode: 'flat'; newRevenue: number };

type ScenarioProps = {
  initialMRR: number;
  churnRate: number;
  months: number;
} & (RateGrowthProps | FlatGrowthProps);

const computeScenarioProjection = (props: ScenarioProps) => {
  const {
    initialMRR,
    churnRate,
    growthMode,
    months,
    growthRate = null,
    newRevenue = null
  } = props;
  const newScenario = [];

  for (let i = 0; i <= months; i++) {
    if (i === 0) {
      const startingMRR = initialMRR;
      const churnedRevenue = 0;
      const addedRevenue = 0;

      const endingMRR = initialMRR - churnedRevenue + addedRevenue;
      const total = endingMRR;

      newScenario[i] = {
        churnedRevenue,
        newRevenue: addedRevenue,
        startingMRR,
        endingMRR,
        total
      };
    } else {
      const startingMRR = newScenario[i - 1].endingMRR;
      const churnedRevenue = Math.ceil(startingMRR * (churnRate / 100));
      const addedRevenue =
        growthMode === 'rate'
          ? Math.ceil(startingMRR * (growthRate / 100))
          : newRevenue;
      const endingMRR = startingMRR - churnedRevenue + addedRevenue;

      const total = newScenario[i - 1].total + endingMRR;

      newScenario[i] = {
        churnedRevenue,
        newRevenue: addedRevenue,
        startingMRR,
        endingMRR,
        total
      };
    }
  }

  return newScenario;
};

export function useScenario(props: any) {
  const [churnRate, setChurnRate] = React.useState(props.churnRate);
  const [initialMRR, setInitialMRR] = React.useState(props.initialMRR);
  const [newRevenue, setNewRevenue] = React.useState(props.newRevenue);
  const [growthMode, setGrowthMode] = React.useState(props.growthMode);
  const [growthRate, setGrowthRate] = React.useState(props.growthRate);
  const [projection, setProjection] = React.useState(
    computeScenarioProjection({
      initialMRR,
      churnRate,
      newRevenue,
      growthMode,
      growthRate
    })
  );

  React.useEffect(() => {
    setProjection(
      computeScenarioProjection({
        initialMRR,
        churnRate,
        newRevenue,
        growthMode,
        growthRate,
        months: props.months
      })
    );
  }, [churnRate, initialMRR, newRevenue, growthRate, growthMode, props.months]);

  return {
    churnRate,
    initialMRR,
    newRevenue,
    projection,
    growthMode,
    growthRate,
    setChurnRate,
    setInitialMRR,
    setNewRevenue,
    setGrowthMode,
    setGrowthRate
  };
}
