import * as React from 'react';
import { Flex } from '..';
import { Box } from 'rebass';
import { keyframes } from '@emotion/core';

export const Loading = (props: any) => (
  <Flex
    alignItems={'center'}
    justifyContent={'center'}
    sx={{ height: '100%', width: '100%', color: 'primary' }}
  >
    <LoadingIcon {...props} />
  </Flex>
);

export const load = keyframes`
 0% { transform: rotate(0deg); }
 100% { transform: rotate(360deg); }
`;

export function LoadingIcon(props: {
  size?: number;
  color?: string;
  bg?: string;
}): React.ReactElement {
  const { size = 48, color, bg } = props;
  const spinner = size / 7.5;

  return (
    <Box
      sx={{
        border: t => `${spinner}px solid ${bg || t.colors.marketingBG}`,
        borderTop: t => `${spinner}px solid ${color || t.colors.primary}`,
        borderRadius: '50%',
        width: size,
        height: size,
        animation: `${load} 0.9s linear infinite`
      }}
    />
  );
}
